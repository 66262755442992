import qs from 'qs';
import { useRootSelector } from '../../store/hooks';
import { selectLocationConfig } from '../../store/features/locationConfig';

export function useIsVisualEditorPreview() {
  const { search } = useRootSelector(selectLocationConfig);

  const { visualEditorPreview } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  return !!visualEditorPreview;
}
